@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.logo{
background-image: url('./assets/images/chatboot.png');
background-repeat: no-repeat;
background-size: cover;
}
